<template>
  <main>
    <PageTitle
      title="商城付款設定"
      icon="chevron_left"
      btn="新增"
      @btnClick="dialog.edit = true"
      @iconClick="$router.push({ name: 'MemberShopParameterSetting' })"
    />

    <el-table v-loading="loading.table" :data="displayData">
      <el-table-column label="付款方式名稱" prop="name" align="center" />
      <el-table-column label="付款方式種類" prop="type" align="center" />
      <el-table-column label="啟用" prop="enabled" align="center">
        <template slot-scope="scope">
          <Tag :type="scope.row.enabled.tagType">{{ scope.row.enabled.label }}</Tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            @edit="onRowEdit(scope.row)"
            @delete="onRowDelete(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh"
    />

    <DeleteDialog
      v-if="dialog.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="dialog.delete = false, selectRow = null"
      @delete="deletePayment"
    />

    <MemberShopPaymentConfigEditModal
      v-if="dialog.edit"
      :paymentId="selectRow?.id"
      @close="dialog.edit = false, selectRow = null"
      @refresh="refresh"
    />
  </main>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import TableEditBtnGroup from '@/components/Button/TableEditBtnGroup.vue'
import MemberShopPaymentConfigEditModal from './MemberShopPaymentConfigEditModal.vue'
import Tag from '@/components/Tag/Tag.vue'
import { GetMemberShopPaymentConfig, GetMemberShopPaymentConfigCount, DeleteMemberShopPaymentConfig } from '@/api/memberShop'
import { useTable } from '@/use/table'
import store from '@/store'
import { map, get } from 'lodash'
import { orderPaymentTypeConfig } from '@/config/memberShop'

export default defineComponent({
  name: 'MemberShopPaymentSetting',
  components: { PageTitle, TableEditBtnGroup, Tag, MemberShopPaymentConfigEditModal },
  setup (props) {
    const { tableData, tableDataCount, tableOptions, selectRow, dialog, loading, pageStartIndex } = useTable()
    const shopId = computed(() => store.getters.shop)
    const displayData = computed(() => {
      const data = tableData.value
      return map(data, i => {
        return {
          id: i.id,
          name: i.name,
          type: get(orderPaymentTypeConfig, `${i.type}.label`),
          enabled: i.enable ? { label: '是', tagType: 'action' } : { label: '否', tagType: 'info' },
        }
      })
    })
    const onRowEdit = (row) => {
      selectRow.value = row
      dialog.edit = true
    }
    const onRowDelete = (row) => {
      selectRow.value = row
      dialog.delete = true
    }
    const deletePayment = async () => {
      const [, err] = await DeleteMemberShopPaymentConfig({
        shopId: shopId.value,
        id: selectRow.value.id,
      })
      if (err) return window.$message.error(err)
      window.$message.success('刪除付款方式成功！')
      dialog.delete = false
      selectRow.value = null
      await refresh()
    }
    const getMemberShopPaymentConfig = async (payload) => {
      const [res, err] = await GetMemberShopPaymentConfig(payload)
      if (err) window.$message.error(err)
      tableData.value = res
    }
    const getMemberShopPaymentConfigCount = async (payload) => {
      const [res, err] = await GetMemberShopPaymentConfigCount(payload)
      if (err) window.$message.error(err)
      tableDataCount.value = res.count
    }
    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
      }
      await Promise.allSettled([
        getMemberShopPaymentConfig(payload),
        getMemberShopPaymentConfigCount(payload),
      ])
    }
    const refresh = async () => {
      if (loading.table) return
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(async () => {
      await refresh()
    })
    return {
      get,
      onRowEdit,
      onRowDelete,
      loading,
      dialog,
      tableOptions,
      tableDataCount,
      refresh,
      displayData,
      deletePayment,
      selectRow,
    }
  },
})
</script>

<style scoped lang="postcss">

</style>
